import React from "react";
import {
  SEO,
  PageLayout,
  Section,
  FishermanHeader,
  BackgroundImages,
  MarkdownContent,
  MultipleComponentIterator,
  FadeReveal,
  FullImageCard,
  InternalLink,
  TeamMemberV2,
} from "@bluefin/components";
import { graphql } from "gatsby";
import { Card } from "semantic-ui-react";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import { createTeamMemberSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class TeamPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
          ogUrl={"https://www.hair-lab-salon.com/team/"}
        />
        <PageLayout hero={false} className={"team-page"}>
          <Section className={"default-hero-section"}>
            <FishermanHeader
              as={"h1"}
              className={"tagline"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "team_hero_header",
                defaultValue: "Team Members With Flair",
              })}
            />
            <BackgroundImages
              images={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "HeroElement",
                componentIdentifier: "hero_images",
              })}
              carouselProps={{
                leftArrowIcon: "arrow-left",
                rightArrowIcon: "arrow-right",
              }}
              imageProps={{ loadStrategy: "eager" }}
            />
          </Section>
          <Section className={"team-section"}>
            <div className={"content-container"}>
              <FishermanHeader
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "staff_section_header",
                  defaultValue: "Our Team",
                })}
              />
              <MarkdownContent
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "staff_section_description",
                })}
              />
            </div>
            <Card.Group itemsPerRow={4} className={"team-members"}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessTeam.nodes}
                components={[
                  {
                    component: (
                      <FadeReveal className={"ui card"} triggerOnce={true} />
                    ),
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <FullImageCard
                            className={"team-member-card"}
                            link={true}
                            as={InternalLink}
                            event={{
                              category: "Team",
                              action: "View Team Member",
                            }}
                          />
                        ),
                        propMap: {
                          key: "_id",
                          image: "gatsbyImage",
                          to: createTeamMemberSlug,
                        },
                        children: [
                          {
                            component: <TeamMemberV2.Name />,
                            propMap: { name: "name" },
                          },
                          {
                            component: <TeamMemberV2.Role />,
                            propMap: { role: "role" },
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Team" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
